.not {
  max-width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 80px 0 0 0;
}

.not__image {
  width: 83px;
  height: 83px;
}

.not__title {
  font-family: 'Roboto Slab', sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.15em;
}

.not__paragraph {
  font-family: 'Roboto Slab', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.33em;
  width: 250px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .not {
    margin: 32px 0 0 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .not {
    margin: 40px 0 0 0;
  }
}
