.menu {
  z-index: 1;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.menu__container-loggedin,
.menu__container-loggedout {
  width: 320px;
  height: auto;
  max-height: calc(100% - 16px);
  background-color: #1a1b22;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.menu__over {
  height: 56px;
  width: 100%;
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.2);
  display: flex;
}

.menu__link {
  text-decoration: none;
  list-style-type: none;
  display: flex;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
}

.menu__logo {
  margin: 16px 16px 16px 16px;
  font-family: 'Roboto Slab', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5em;
  user-select: none;
}

.menu__button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
  align-self: flex-end;
  display: flex;
}

.menu__close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
}

.menu__home {
  margin: 16px 0 0 16px;
  line-height: 3em;
}

.menu__signin,
.menu__signout-button {
  width: 288px;
  height: 56px;
  margin: 22px 0 24px 16px;
  border-radius: 100px;
  color: inherit;
  border: 1px solid #fff;
  color: #fff !important;
  font-weight: 500;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  line-height: 56px;
  text-align: center;
}

.menu__articles {
  margin: 15px 0 0 16px;
  line-height: 3em;
}

.menu__signout-button {
  display: flex;
  border-radius: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin: 20px 16px 0 16px;
  background-color: transparent;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.33em;
  color: inherit;
  padding: 0 19px 0 19px;
  color: #fff;
  width: 100%;
  width: auto;
  line-height: normal;
}

.menu__signout-image {
  margin: 0 0 0 5px;
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 768px) {
  .menu {
    justify-content: center;
  }

  .menu__container-loggedin,
  .menu__container-loggedout {
    width: 100%;
    height: auto;
    max-height: calc(100% - 16px);
  }

  .menu__signin {
    width: calc(100% - 32px);
    box-sizing: border-box;
    text-align: center; 
  }

  .menu__signout-button {
    margin: 20px 16px 16px 16px;
    width: calc(100% - 32px);
    padding: 10px 0;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1327px) {
}

@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 682px) {
}

@media screen and (max-width: 520px) {
}

@media screen and (max-width: 320px) {
  .menu {
    justify-content: center;
  }

  .menu__container-loggedin,
  .menu__container-loggedout {
    width: 100%;
    height: auto;
    max-height: calc(100% - 16px);
  }

  .menu__signout-button {
    margin: 20px 16px 16px 16px;
  }
}

/* .menu {
  fill: var(--main-primary, #1a1b22);
  z-index: 1;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 102%;
  height: 103%;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
}

.menu__container-loggedin {
  margin: 16px 6px 0 0;
  width: 320px;
  height: 285px;
  background-color: #1a1b22;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.menu__container-loggedout {
  margin: 16px 6px 0 0;
  width: 320px;
  height: 230px;
  background-color: #1a1b22;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.menu__over {
  height: 56px;
  width: 320px;
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.2);
}

.menu__link {
  color: #fff;
  font-family: 'Roboto Slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 56px;
  letter-spacing: 0px;
  text-decoration: none;
  list-style-type: none;
}

.menu__logo {
  font-family: 'Roboto Slab', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 56px;
  margin: 20px 16px 16px 16px;
  user-select: none;
}

.menu__button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
  align-self: flex-end;
  display: flex;
}

.menu__close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
}

.menu__home {
  margin: 16px 0 0 16px;
  line-height: 3em;
}

.menu__signin {
  color: #fff;
  width: 288px;
  height: 56px;
  margin: 22px 0 24px 16px;
  border-radius: 100px;
  border: 1px solid #fff;
  font-weight: 500;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  line-height: 56px;
}

.menu__articles {
  margin: 15px 0 0 16px;
  line-height: 3em;
}

.menu__signout-button {
  color: #fff;
  display: flex;
  border-radius: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin: 20px 16px 0 16px;
  background-color: initial;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.33em;
  color: inherit;
  line-height: 56px;
  border: 1px solid #fff;
}

.menu__signout-image {
  margin: 0 0 0 5px;
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 1440px) {
  .menu__container-loggedin {
    width: 100%;
    height: auto;
    background-color: #1a1b22;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px 6px 0;
  }

  .menu__over {
    height: 56px;
    width: 100%;
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.2);
  }

  .menu__close {
    margin: auto;
  }

  .menu__signout-button {
    margin-bottom: 20px;
    padding: 16px 0;
  }
}

@media screen and (max-width: 1000px) {
  .menu__container-loggedin,
  .menu__container-loggedout {
    max-width: 100%;
    width: 1000px;
    left: 0;
    right: auto;
    margin: 16px 0;
  }

  .menu__signin {
    width: 950px;
  }

  .menu__close {
    margin: 2px 10px 104px;
  }
}

@media screen and (max-width: 768px) {
  .menu__logo {
    margin: 12px 10px 104px;
    margin: 16px 0;
  }
  .menu__container-loggedin,
  .menu__container-loggedout {
    max-width: 100%;
    width: 330px;
    left: 0;
    right: auto;
    margin: 16px 0;
  }

  .menu__signin {
    text-align: center;
  }

  .menu__signout-button {
    margin-bottom: 10px;
    padding: 10px 0;
    width: 100%;
  }

  .menu__over {
    margin: 12px 10px 104px;
  }

  .menu__close {
    margin: 12px 10px 104px;
  }
}

@media screen and (max-width: 320px) {
  .menu__logo {
    margin: 16px 0;
  }
  .menu__container-loggedin,
  .menu__container-loggedout {
    max-width: 100%;
    width: 400px;
    left: 0;
    right: auto;
    margin: 16px 0;
  }

  .menu__signout-button {
    margin-bottom: 10px;
    padding: 10px 0;
    width: 100%;
    width: 288px;
    left: 16px;
  }

  .menu__close {
    margin: 15px;
  }
} */
