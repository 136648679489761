@import url(./vendor/fonts/fonts.css);
@import url(./components/App/app.css);
@import url(./components/About/about.css);
@import url(./components/Footer/footer.css);
@import url(./components/Header/header.css);
@import url(./components/MobileMenu/menu.css);
@import url(./components/ModalWithForm/modalwithform.css);
@import url(./components/NewsCard/newscard.css);
@import url(./components/NotFound/notfound.css);
@import url(./components/NewsCardList/newscardlist.css);
@import url(./components/Navigation/navigation.css);
@import url(./components/Preloader/preloader.css);
@import url(./components/SavedNews/savednews.css);
@import url(./components/Search/search.css);
@import url(./components/SearchForm/searchform.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto', 'Roboto Slab', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
