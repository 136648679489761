.page {
  font-family: 'Roboto', sans-serif;
  position: relative;
  margin: 0 auto 0 auto;
  background-color: #ffffff;
  max-width: 1440px;
}

.page__wrapper {
  margin: 0 auto;
  max-width: 1440px;
}
