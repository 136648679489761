.search-home__content {
  display: flex;
  flex-direction: column;
  width: 608px;
  margin-right: auto;
  margin-left: auto;
}

.search-home__text {
  flex-direction: column;
  display: flex;
  width: 100%;
  text-align: start;
}

.search-home__title {
  color: white;
  font-family: 'Roboto Slab', Arial, sans-serif;
  font-size: 60px;
  font-weight: 400;
  line-height: 64px;
}

.search-home__info {
  color: white;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.search__fieldset {
  background-color: #fff;
  width: 608px;
  height: 64px;
  border: none;
  border-radius: 100px;
  margin: 68px auto 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  justify-content: space-between;
  align-items: flex-start;
}

.search__input {
  font-family: 'Roboto', Arial, sans-serif;
  height: 64px;
  border: 0;
  width: 564px;
  border-radius: 35px;
  padding: 0 0 0 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: black;
}

.search__input::placeholder {
  color: #b6bcbf;
}

.search__input:focus {
  outline: none;
}

.search__errors {
  color: #ff0000;
  margin: 3px 0 3px 24px;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  line-height: 1.2em;
}

.search__search {
  height: 64px;
  padding: 0;
  border: 0;
  background: #2f71e5;
  width: 168px;
  position: absolute;
  right: 18px;
  border-radius: 35px;
  top: 0;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: white;
  cursor: pointer;
}

.search__search:hover {
  background-color: #347eff;
  cursor: pointer;
}

.search__search:active {
  background-color: #2a65cc;
}

.search__search-mobile {
  display: none;
}

@media screen and (max-width: 1000px) {
  .search-home__content {
    width: 448px;
    margin-right: auto;
    margin-left: auto;
  }

  .search-home__title {
    font-size: 36px;
    line-height: 40px;
    margin: 38px 0 16px;
  }

  .search-home__info {
    margin: 0 0 48px;
  }
  .search__input {
    width: 424px;
  }

  .search__search {
    right: -2px;
  }
}

@media screen and (max-width: 520px) {
  .search-home__content {
    max-width: calc(100% - 32px);
    width: 100%;
  }

  .search-home__title {
    margin: 30px 0 16px;
  }

  .search__input {
    margin-bottom: 16px;
    width: 93.5%;
  }

  .search__search {
    position: relative;
    width: 100%;
  }
}
