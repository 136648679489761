@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Inter-Roboto-Web/Roboto-400-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./Inter-Roboto-Web/Roboto-500-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./Inter-Roboto-Web/Roboto-700-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Inter-Roboto-Web/Roboto-slab-400-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./Inter-Roboto-Web/Roboto-500-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./Inter-Roboto-Web/Roboto-slab-700-Regular.woff2") format("woff2");
}
