.preloader {
  background-color: #fff;
  margin: 0;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preloader__body {
  display: block;
  width: 50px;
  height: 50px;
  border: 4px solid #444;
  border-bottom-color: #888;
  border-radius: 50%;
  margin: 0;
  animation: spin 0.75s infinite linear;
}

.preloader__text {
  color: #b6bcbf;
  margin: 25px 0 0 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.33em;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
