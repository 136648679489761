.header {
  background: url(../../images/georgia-de-lotz--UsJoNxLaNo-unsplash.svg);
  /* max-width: 1440px; */
  height: 576px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  /* background-size: cover; */
}

@media screen and (max-width: 1440px) {
  .header {
    /* background-image: url(../../images/georgia-de-lotz--UsJoNxLaNo-unsplash-tablet.svg); */
    height: 576px;
    width: auto;
  }
}

@media screen and (max-width: 767px) {
  .header {
    background-image: url(../../images/georgia-de-lotz--UsJoNxLaNo-unsplash-mobile.svg);
    /* height: 536px; */
    background-size: cover;
  }
}
