.saved {
  margin: 40px 0 0 0;
}

.saved__container {
  margin: 0 104px 56px 104px;
}

.saved__title {
  color: rgba(26, 27, 34, 0.5);
  font-family: 'Roboto Slab', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33em;
}

.saved__header {
  font-family: 'Roboto Slab', sans-serif;
  font-size: 40px;
  line-height: 1.15em;
  font-weight: 400;
  max-width: 530px;
}

.saved__words {
  font-family: 'Roboto Slab', sans-serif;
  font-weight: 400;
  font-size: 18;
  line-height: 24px;
}

.saved__bold {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

@media screen and (max-width: 1440px) {
  .saved {
    margin: 24px 0 0 0;
    /* margin: 0 104px; */
    /* margin: 0 65px; */
  }

  .saved__container {
    margin: 0 40px 56px 40px;
  }

  .saved__title {
    font-size: 18px;
    line-height: 1.5em;
  }

  .saved__header {
    font-size: 30px;
    line-height: 1.133em;
    width: 336px;
  }

  .saved__words {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .saved {
    margin: 8px 0 0 0;
  }

  .saved__container {
    margin: 0 16px 32px 16px;
  }

  .saved__title {
    font-size: 12px;
    line-height: 24px;
    width: 288px;
  }

  .saved__header {
    width: 288px;
    height: 68px;
    font-size: 30px;
    line-height: 34px;
  }

  .saved__words {
    margin: 50px 0 0 0;
    width: 288px;
    height: 48px;
  }
}
