.about {
  display: flex;
  gap: 56px;
  max-width: 1440px;
  margin: 80px 0 0 0;
  padding-left: 104px;
  padding-right: 104px;
  align-items: flex-start;
}

.about__image {
  width: 464px;
  height: 464px;
  border-radius: 50%;
  margin: 0 0 80px;
}

.about__container {
  width: 55%;
  margin: 50px 0 0 56px;
  font-weight: 400;
  color: #1a1b22;
}

.about__title {
  width: 600px;
  height: 46px;
  font-family: 'Roboto Slab', Arial, sans-serif;
  font-size: 40px;
  line-height: 1.15em;
  font-weight: 400;
  margin: 0 0 24px;
}

.about__paragraph {
  width: 600px;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@media screen and (max-width: 1440px) {
  .about__container {
    width: 70%;
    margin: 50px 0 0 30px;
  }

  .about__title,
  .about__paragraph {
    width: 100%;
  }
}

@media screen and (max-width: 1327px) {
  .about__container {
    width: 70%;
    margin: 50px 0 0 30px;
  }

  .about__title,
  .about__paragraph {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .about {
    padding-left: 40px;
    padding-right: 40px;
  }

  .about__container {
    width: 100%;
    margin: 20px 0 0 0;
  }

  .about__title,
  .about__paragraph {
    width: 100%;
  }
}

@media screen and (max-width: 999px) {
  .about {
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
  }

  .about__image {
    width: 272px;
    height: 272px;
    margin: 0 auto 40px;
  }

  .about__container {
    width: 100%;
  }

  .about__title {
    font-size: 30px;
    line-height: 1.33em;
    margin-bottom: 12px;
  }

  .about__paragraph {
    font-size: 16px;
    line-height: 1.33em;
  }
}

@media screen and (max-width: 768px) {
  .about {
    padding-left: 20px;
    padding-right: 20px;
  }

  .about__image {
    width: 272px;
    height: 272px;
    margin-bottom: 40px;
  }

  .about__container {
    width: 100%;
    margin: 20px 0 0 0;
  }

  .about__title {
    font-size: 30px;
  }
}

@media screen and (max-width: 682px) {
  .about {
    display: flex;
    gap: 30px;
  }

  .about__image {
    width: 232px;
    height: 232px;
    margin: 0 auto 24px;
  }

  .about__title {
    margin: 0 auto 16px;
    font-size: 30px;
    line-height: 40px;
  }

  .about__paragraph {
    margin-bottom: 34px;
  }
}

@media screen and (max-width: 540px) {
  .about {
    padding-left: 5px;
    padding-right: 5px;
  }

  .about__image {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }

  .about__container {
    width: 100%;
    margin: 20px 0 0 0;
  }

  .about__title {
    font-size: 28px;
    line-height: 36px;
    margin: 0 auto 12px;
  }

  .about__paragraph {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 450px) {
  .about {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }

  .about__image {
    width: 272px;
    height: 272px;
    margin: 0 auto 20px;
  }

  .about__container {
    width: 100%;
    margin: 0;
  }

  .about__title {
    font-size: 30px;
    line-height: 1.33em;
    margin-bottom: 12px;
  }

  .about__paragraph {
    font-size: 16px;
    line-height: 1.33em;
  }
}

@media screen and (max-width: 320px) {
  .about {
    margin: 32px 16px 0 16px;
    flex-direction: column;
    padding-left: 1px;
  }

  .about__image {
    width: 232px;
    height: 232px;
    margin: 0 8px 0 8px;
  }

  .about__container {
    width: 288px;
    margin: 1px 0 128px 0;
  }

  .about__title {
    font-size: 30px;
    line-height: 1.33em;
    width: 288px;
  }

  .about__paragraph {
    font-size: 18px;
    width: 288px;
  }
}
