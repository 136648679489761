.news {
  background-color: #f5f6f7;
  /* padding: 80px 104px 0; */
  padding: 0 40px 32px 80px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.news__header {
  font-family: 'Roboto Slab', sans-serif;
  font-size: 40px;
  line-height: 1.15em;
  font-weight: 400;
  color: #1a1b22;
  /* margin: 80px auto 64px;
  width: 1232px; */
  margin-bottom: 10px;
  margin-top: 50px;
  /* margin: 0; */
}

.news__cards {
  display: grid;
  grid-gap: 16px;
  margin: 64px 0 62px 0;
  gap: 16px;
  padding: 0;
  /* grid-template-columns: repeat(3, 400px); */
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.news__show {
  background-color: #fff;
  width: 288px;
  height: 64px;
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.33em;
  color: #1a1b22;
  border-radius: 100px;
  border: none;
  padding: 0;
  margin: 24px auto 40px auto;
}

.news__show:hover {
  background-color: #e8e8e8;
  opacity: 0.8;
  cursor: pointer;
}

.news__show:active {
  background-color: #e8e8e8;
}

@media screen and (max-width: 1440px) {
  .news {
    /* padding: 40px 40px 32px 40px; */
    /* padding: 0 40px 32px 80px; */
  }

  .news__header {
    font-size: 30px;
    line-height: 1.133em;
  }

  .news__cards {
    /* grid-template-columns: repeat(3, 368px);  */
    gap: 32px 16px;
    margin: 48px 0 0 0;
  }

  .news__show {
    width: 240px;
    height: 56px;
    margin: 32px auto 8px auto;
  }
}

@media screen and (max-width: 1327px) {
  .news {
    padding: 40px 40px 32px 40px;
    margin: 0;
  }

  .news__header {
    font-size: 30px;
    line-height: 1.133em;
    padding-left: 20px;
  }

  .news__cards {
    grid-template-columns: repeat(3, 300px);
    gap: 8px 8px;
    /* margin: 32px 0 0 0; */
    margin: 23px 10px 10px 23px;
    justify-content: center;
  }

  .news__show {
    width: 240px;
    height: 56px;
    margin: 32px auto 8px auto;
  }
}

@media screen and (max-width: 1000px) {
  .news__cards {
    grid-template-columns: repeat(3, 224px);
    /* gap: 20px 20px;
    margin: 32px 10px 10px 1px; */
    gap: 24px 16px;
    /* margin: 32px 10px 10px 32px; */
    margin: 23px 10px 10px 23px;
  }

  .news__show {
    width: 240px;
    height: 56px;
    margin: 32px auto 8px auto;
  }
}

@media screen and (max-width: 830px) {
  .news {
    padding: 32px 23px 32px;
    flex-direction: column;
    grid-gap: 16px;
  }

  .news__header {
    font-size: 30px;
    line-height: 1.133em;
    margin: 0;
    margin-bottom: 30px;
    margin-top: 13px;
  }

  .news__cards {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
    padding: 0 25px;
  }

  .news__show {
    width: 240px;
    height: 56px;
    margin: 16px auto 24px auto;
  }
}

@media screen and (max-width: 768px) {
  .news {
    padding: 32px 23px 32px;
    flex-direction: column;
    grid-gap: 16px;
  }

  .news__header {
    font-size: 30px;
    line-height: 1.133em;
    margin: 0;
    margin-bottom: 30px;
    margin-top: 13px;
  }

  .news__cards {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
    padding: 0 25px;
  }

  .news__show {
    width: 240px;
    height: 56px;
    margin: 16px auto 24px auto;
  }
}

@media screen and (min-width: 501px) and (max-width: 725px) {
  .news__header {
    margin-bottom: 30px;
    margin-top: 13px;
    text-align: center;
    margin-right: 25px;
  }

  .news__cards {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 16px;
    padding: 0 25px;
    justify-content: center;
  }
}

@media screen and (min-width: 321px) and (max-width: 500px) {
  .news__header {
    margin-bottom: 30px;
    margin-top: 13px;
    text-align: center;
    margin-right: 25px;
  }
  .news__cards {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 16px;
    padding: 0 25px;
    justify-content: center;
  }
}

@media screen and (max-width: 320px) {
  .news__header {
    margin-bottom: 30px;
    margin-top: 13px;
  }
  .news__cards {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 16px;
    padding: 0 25px;
  }
}
